import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { reqArticleDetail } from "../../util/api"
import { message } from "antd"
import './index.css'

/**
 * 新闻详情
 * @returns 
 */
const NewsDetail = () => {

  const naviget = useNavigate()
  const [searchParams] = useSearchParams()
  const detailId = searchParams.get('id')
  const [newsType, setNewsType] = useState(searchParams.get('type'))
  const [content, setContent] = useState('')
  const [titile, setTitle] = useState('')
  const [time, setTime] = useState('')

  useEffect(() => {
    if (detailId) {
      reqNewsDetail()
    }
    else {
      naviget('*')
    }
  }, [detailId])

  /**
   * 点击 新闻tab
   * @param {*} type 
   */
  const onClickNewsTab = (type) => {
    setNewsType(type)
    naviget('/newslist?type=' + type)
  }

  /**
   * 获取新闻详情
   */
  const reqNewsDetail = () => {
    reqArticleDetail({ id: searchParams.get('id') }).then((res) => {
      if (res.res === 1) {
        setTitle(res.data.title)
        setTime(res.data.create_time.substring(0, 10))
        setContent(res.data.content)
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  return <div className="w-full h-full page_newsdetail">
    <div className='w-1200 m-auto mt-16 flex flex-row justify-between'>

      <div className="" style={{ width: '180px' }}>
        <div className="flex flex-row items-center text-sky-400">
          <div className="w-1 h-1 bg-sky-400 mr-4"></div>
          <div className={`w-20 h-8 leading-8 cursor-pointer rounded-md text-center ${newsType === 'news' ? 'bg-sky-400' : ''} ${newsType === 'news' ? 'text-white' : ''}`} onClick={() => { onClickNewsTab('news') }}>公司新闻</div>
        </div>
        <div className="flex flex-row items-center text-sky-400 mt-2">
          <div className="w-1 h-1 bg-sky-400 mr-4"></div>
          <div className={`w-20 h-8 leading-8 cursor-pointer rounded-md text-center ${newsType === 'classroom' ? 'bg-sky-400' : ''} ${newsType === 'classroom' ? 'text-white' : ''}`} onClick={() => { onClickNewsTab('classroom') }}>家长课堂</div>
        </div>
        <div className="flex flex-row items-center text-sky-400 mt-2">
          <div className="w-1 h-1 bg-sky-400 mr-4"></div>
          <div className={`w-20 h-8 leading-8 cursor-pointer rounded-md text-center ${newsType === 'brain' ? 'bg-sky-400' : ''} ${newsType === 'brain' ? 'text-white' : ''}`} onClick={() => { onClickNewsTab('brain') }}>科学用脑</div>
        </div>
      </div>

      <div className="" style={{ width: '1000px' }}>
        <div className="h-8 leading-8 mb-2">{newsType === 'news' ? '公司新闻' : newsType === 'classroom' ? '家长课堂' : '科学用脑'}</div>
        <div className="w-full flex flex-wrap bg-white p-8 mb-8 rounded-md">
          <div className="w-full text-2xl text-center font-bold">{titile}</div>
          <div className="w-full mt-8 text-gray-400 text-base">{time}</div>
          <div className="w-full mt-8 text-base newsdetail" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  </div>
}

export default NewsDetail