
import SysExhibit from '../../components/SysExhibit'
import SysAuditionBanner from '../../components/SysAuditionBanner'

import home1 from '../../assets/img/home/home1.png'
import home2 from '../../assets/img/home/home2.png'
import home3 from '../../assets/img/home/home3.png'
import home4 from '../../assets/img/home/home4.png'
import home5 from '../../assets/img/home/home5.png'
import home6 from '../../assets/img/home/home6.png'
import home7 from '../../assets/img/home/home7.png'
import home8 from '../../assets/img/home/home8.png'
import home9 from '../../assets/img/home/home9.png'
import home10 from '../../assets/img/home/home10.png'
import home11 from '../../assets/img/home/home11.png'
import home12 from '../../assets/img/home/home12.png'
import home13 from '../../assets/img/home/home13.png'
import home14 from '../../assets/img/home/home14.png'

import number1 from '../../assets/img/home/1.png'
import number2 from '../../assets/img/home/2.png'
import number3 from '../../assets/img/home/3.png'
import number4 from '../../assets/img/home/4.png'
import number5 from '../../assets/img/home/5.png'
import number6 from '../../assets/img/home/6.png'
import number7 from '../../assets/img/home/7.png'
import number8 from '../../assets/img/home/8.png'
import { useEffect } from 'react'
import SysBtn from '../../components/SysBtn'
import useBearStore from '../../zustand/state'


/**
 * 首页
 * @returns 
 */
const Home = () => {

  const { setAuditionDisplay } = useBearStore()

  useEffect(() => {
    // console.log(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_URL)
  }, [])

  /**
   * 点击 试听
   */
  const onClickAudition = () => {
    setAuditionDisplay(true)
  }

  return <div className="w-full h-full max-w-1920 min-w-1200 bg-white page_home mx-auto">

    <div className='w-full relative'>
      <div className='w-full bg-cover' style={{ height: '400px', backgroundImage: `url(${home1})`, backgroundPositionX: 'center' }}></div>
      <div className='absolute' style={{ left: 'calc(50% - 110px)', bottom: '25%' }}><SysBtn btnType='cooperateBtn' btnText='免费试听' clickCallback={onClickAudition} /></div>
    </div>

    <div className='w-full pt-16'>
      <div className='text-center font-bold mb-6' style={{ fontSize: '2.25rem' }}>新智思维课堂，用脑科学从根源解决问题</div>
      <div className='w-full bg-cover' style={{ height: '540px', backgroundImage: `url(${home2})`, backgroundPositionX: 'center' }}></div>
    </div>

    <div className='w-full pt-16'>
      <div className='text-center font-bold mb-6' style={{ fontSize: '2.25rem' }}>选择新智思维课堂的八大理由</div>
      <div className='w-full bg-cover' style={{ height: '598px', backgroundImage: `url(${home3})`, backgroundPositionX: 'center' }}></div>
    </div>

    {/* 1 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number1} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>三脑融合，测评训练精准可靠</div>
      </div>
      <div className='text-sm w-2/3 rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>以教育学为核心，使用脑机反馈技术，利用到积极心理学、实验范式、自我激励、自我觉察、心理疏导、价值观、世界观等教育方法，持续训练提升大脑认知能力，并将训练的能力迁移到学习生活中。</div>
      <img className='w-full' src={home4} alt='' />
      <div className='w-2/3 flex flex-row justify-around items-center mx-auto my-6'>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>脑电技术</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>认知神经学</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>教育学</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>人工智能</div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-yellow-300 mr-2'></div>
          <div className='text-base text-gray-500'>儿童发展心理学</div>
        </div>
      </div>
    </div>

    {/* 2 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number2} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>专业脑科学设备，从根源找出弱项能力的原因</div>
      </div>
      <div className='text-sm w-2/3 rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>专注度、放松度、大脑优势节律、左右脑均衡性、脑状况、脑活性、脑负荷、脑疲劳、情绪价值、焦虑度、疲劳度等大脑生理技术指标一目了然。</div>
      <img className='w-full' src={home5} alt='' />
    </div>

    {/* 3 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number3} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>靶向孩子心理年龄，能力框架层层递进</div>
      </div>
      <div className='text-sm w-2/3 rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>十二类大脑核心能力，基于儿童发展心理学和我们团队长期的最佳训练实践展开课程设计。能力框架具有纵向层层递进、横向关联穿插、螺旋式增效的特点。</div>
      <img className='w-full' src={home6} alt='' />
    </div>

    {/* 4 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number4} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>沉浸式乐课堂，寓教于乐</div>
      </div>
      <div className='text-sm w-2/3 rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500'>阿友、小睿、思思等五只可爱勇敢的小蚂蚁，穿越森林、海洋、太空、草原、恐龙等9大故事场景，2000多个有趣的故事任务，等着同学们一起去挑战！</div>
      <img className='w-full' src={home7} alt='' />
      <div className='text-sm w-2/5 rounded-full border-8 border-gray-200 p-2 px-6 my-5 text-gray-500 text-center'>更多充满激情的奇幻世界，小蚂蚁等着同学们戴上脑波仪去探索！</div>
      <img className='w-full' src={home8} alt='' />
    </div>

    {/* 5 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number5} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>课节层层递进，环环相扣，因脑施教</div>
      </div>
      <img className='w-full mt-12' src={home9} alt='' />
    </div>

    {/* 6 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number6} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>十二类大脑核心素养，我都行</div>
      </div>
      <img className='w-full mt-12' src={home10} alt='' />
    </div>

    {/* 7 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number7} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>九步教学法，步步基于神经科学</div>
      </div>
      <img className='w-full mt-12' style={{ width: '956px' }} src={home11} alt='' />
    </div>

    {/* 8 */}
    <div className='w-1200 pt-16 flex flex-col items-center mx-auto'>
      <div className='flex flex-row items-end'>
        <img style={{ width: '42px' }} src={number8} alt='' />
        <div className='text-3xl w-auto ml-2' style={{ lineHeight: '3.5rem' }}>让训练效果看得见</div>
      </div>
      <div className='w-full p-12 rounded-xl shadow-2xl'>
        <div className='flex flex-row items-center'>
          <div className='w-2 h-2 rounded-full bg-blue-500 mr-2'></div>
          <div className='text-base text-gray-500 font-bold'>让训练效果看得见</div>
        </div>
        <img className='w-full mt-8' style={{ width: '1100px' }} src={home12} alt='' />
        <div className='flex flex-row items-center mt-10'>
          <div className='w-2 h-2 rounded-full bg-blue-500 mr-2'></div>
          <div className='text-base text-gray-500 font-bold'>妈妈说，孩子的能力提升看得见</div>
        </div>
        <img className='w-full mt-8' style={{ width: '1100px' }} src={home13} alt='' />
        <div className='flex flex-row items-center mt-10'>
          <div className='w-2 h-2 rounded-full bg-blue-500 mr-2'></div>
          <div className='text-base text-gray-500 font-bold'>老师说，孩子的能力改善看得见</div>
        </div>
        <img className='w-full mt-8' style={{ width: '1100px' }} src={home14} alt='' />
      </div>
    </div>

    {/* 荣誉与资质 */}
    <SysExhibit />

    {/* 试听 */}
    <SysAuditionBanner />

  </div >
}

export default Home